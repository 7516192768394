<template>
  <base-section id="about-our-product">
    <base-section-heading
			space="2"
      color="grey lighten-2"
      icon="mdi-credit-card-check"
      title="About Patient Payment Portal"
    />
		<v-container>
			<base-body
			class="text-center">
				In light of the current pandemic, the need for secure and save payment processing has become a necessity.  
				<br />
				Patient Payment Portal is a hosted payment page that offers a secure and reliable way to receive patient funds.
			</base-body>

		</v-container>
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Grow your Revenue',
          subtitle: 'Best Productivity',
          text: 'Now you can receive payments over the internet with ease. Simply send your patients to the landing hosted payment page and receive funds.',
          callout: '01',
        },
        {
          title: 'Affordable Prices',
          subtitle: 'Great Deals',
          text: 'Our flat fee of $9.95 per month makes it simple, affordable, and easy to accept payments online.',
          callout: '02',
        },
        {
          title: 'Hassle Free Setup',
          subtitle: 'Peace of Mind',
          text: 'Let our expert staff get you all set up. We handle everything from website development, maintenance, and payment processing.',
          callout: '03',
        },
      ],
    }),
  }
</script>
